var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c(
      "form",
      {
        staticClass: "stat-table__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateTable()
          },
        },
      },
      [
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "От", "input-id": _vm.getFieldId("from") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateFrom,
            callback: function ($$v) {
              _vm.dateFrom = $$v
            },
            expression: "dateFrom",
          },
        }),
        _vm._v(" "),
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "До", "input-id": _vm.getFieldId("to") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateTo,
            callback: function ($$v) {
              _vm.dateTo = $$v
            },
            expression: "dateTo",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-table__content" },
      [
        _vm.rows === null || _vm.rows === undefined
          ? _c("Preloader")
          : _vm.rows.length
          ? _c("div", { staticClass: "stat-client-sessions" }, [
              _c(
                "ul",
                { staticClass: "stat-client-sessions__list" },
                _vm._l(_vm.rows, function (row) {
                  return _c(
                    "li",
                    {
                      staticClass: "stat-client-sessions__item",
                      attrs: { "data-session-item": "" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "stat-client-sessions__link",
                          attrs: { href: "javascript:void(0);" },
                          on: { click: _vm.openItem },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "stat-client-sessions__client-wrap",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "stat-client-sessions__client-id",
                                },
                                [
                                  _vm._v(
                                    "\n                ID: " +
                                      _vm._s(row.client_id) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "stat-client-sessions__client-name",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.name) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "stat-client-sessions__organization",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.organization) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "stat-client-sessions__duration-wrap",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "stat-client-sessions__duration-name",
                                },
                                [
                                  _vm._v(
                                    "\n                Общая продолжительность\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "stat-client-sessions__duration-value",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.total_duration) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "stat-client-sessions__sessions" },
                        [
                          _c(
                            "li",
                            {
                              staticClass:
                                "stat-client-sessions__session-headings",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "stat-client-sessions__session-heading",
                                },
                                [
                                  _vm._v(
                                    "\n                Начало сессии\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "stat-client-sessions__session-heading",
                                },
                                [
                                  _vm._v(
                                    "\n                Конец сессии\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "stat-client-sessions__session-heading",
                                },
                                [
                                  _vm._v(
                                    "\n                Продолжительность сессии\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(row.sessions, function (session) {
                            return _c(
                              "li",
                              { staticClass: "stat-client-sessions__session" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "stat-client-sessions__dates",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "stat-client-sessions__date-wrap",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "stat-client-sessions__date",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(session.from_date) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "stat-client-sessions__time",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(session.from_time) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "stat-client-sessions__date-wrap",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "stat-client-sessions__date",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(session.to_date) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "stat-client-sessions__time",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(session.to_time) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "stat-client-sessions__duration-wrap",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "stat-client-sessions__duration-value",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(session.duration) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }