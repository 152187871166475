var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c(
      "form",
      {
        staticClass: "stat-table__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateTable()
          },
        },
      },
      [
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "От", "input-id": _vm.getFieldId("from") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateFrom,
            callback: function ($$v) {
              _vm.dateFrom = $$v
            },
            expression: "dateFrom",
          },
        }),
        _vm._v(" "),
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "До", "input-id": _vm.getFieldId("to") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateTo,
            callback: function ($$v) {
              _vm.dateTo = $$v
            },
            expression: "dateTo",
          },
        }),
        _vm._v(" "),
        _c("base-field-select-filterable", {
          attrs: {
            label: "Клиент",
            fieldId: _vm.getFieldId("client"),
            values: _vm.getClientList(),
            "current-value": _vm.client,
          },
          on: {
            input: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.client,
            callback: function ($$v) {
              _vm.client = $$v
            },
            expression: "client",
          },
        }),
        _vm._v(" "),
        _c("base-field-select", {
          attrs: {
            values: _vm.types,
            "field-id": _vm.getFieldId("types"),
            "current-value": _vm.type,
            label: "Тип",
          },
          on: {
            input: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-table__content" },
      [
        _vm.rows === null || _vm.rows === undefined
          ? _c("Preloader")
          : _vm.rows.length
          ? _c("div", { staticClass: "stat-table__data" }, [
              _c(
                "ul",
                { staticClass: "stat-table__rows" },
                _vm._l(_vm.rows, function (row) {
                  return _c("li", { staticClass: "stat-table__row" }, [
                    _c("div", { staticClass: "stat-search-item" }, [
                      _c(
                        "div",
                        { staticClass: "stat-search-item__date-wrap" },
                        [
                          _c("div", { staticClass: "stat-search-item__date" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.date) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "stat-search-item__time" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.time) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "stat-search-item__client-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "stat-search-item__client-name" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.full_name) +
                                  "\n\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "stat-search-item__client-organization",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.organization) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "stat-search-item__count-wrap" },
                        [
                          _c("div", { staticClass: "stat-search-item__type" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getType(row.type.toString())) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-search-item__count" },
                            [
                              _vm._v(
                                "\n                Найдено товаров:\n                "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.count) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "stat-search-item__list" },
                        _vm._l(row.data, function (item) {
                          return _c(
                            "li",
                            { staticClass: "stat-search-item__list-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-search-item__item-name" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "stat-search-item__item-value",
                                domProps: { innerHTML: _vm._s(item.value) },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "stat-search-item__pagination_wrap" }, [
                !_vm.isLastPage
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "stat-search-item__pagination button button_primary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.updateTable(true)
                          },
                        },
                      },
                      [_vm._v("\n          Показать еще\n        ")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _c("div", { staticClass: "stat-table__empty" }, [
              _vm._v("\n      Нет данных\n    "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }