<template>
  <div class="stat-page__inner-content">
    <form class="stat-table__form" @submit.prevent="updateTable()">
      <base-field-date
          :label="'От'"
          :input-id="getFieldId('from')"
          @change="updateTable()"
          class="stat-table__field"
          v-model="dateFrom"
      />
      <base-field-date
          :label="'До'"
          :input-id="getFieldId('to')"
          @change="updateTable()"
          class="stat-table__field"
          v-model="dateTo"
      />
    </form>
    <div class="stat-table__content">
      <Preloader v-if="rows === null || rows === undefined"/>

      <div class="stat-client-sessions" v-else-if="rows.length">
        <ul class="stat-client-sessions__list">
          <li class="stat-client-sessions__item" v-for="row in rows" data-session-item>
            <a href="javascript:void(0);" class="stat-client-sessions__link" @click="openItem">
              <span class="stat-client-sessions__client-wrap">
                <span class="stat-client-sessions__client-id">
                  ID: {{ row.client_id }}
                </span>

                <span class="stat-client-sessions__client-name">
                  {{ row.name }}
                </span>

                <span class="stat-client-sessions__organization">
                  {{ row.organization }}
                </span>
              </span>

              <span class="stat-client-sessions__duration-wrap">
                <span class="stat-client-sessions__duration-name">
                  Общая продолжительность
                </span>
                <span class="stat-client-sessions__duration-value">
                  {{ row.total_duration}}
                </span>
              </span>
            </a>
            <ul class="stat-client-sessions__sessions">
              <li class="stat-client-sessions__session-headings">
                <div class="stat-client-sessions__session-heading">
                  Начало сессии
                </div>
                <div class="stat-client-sessions__session-heading">
                  Конец сессии
                </div>
                <div class="stat-client-sessions__session-heading">
                  Продолжительность сессии
                </div>
              </li>
              <li class="stat-client-sessions__session" v-for="session in row.sessions">
                <div class="stat-client-sessions__dates">
                  <div class="stat-client-sessions__date-wrap">
                    <div class="stat-client-sessions__date">
                      {{ session.from_date}}
                    </div>
                    <div class="stat-client-sessions__time">
                      {{ session.from_time}}
                    </div>
                  </div>

                  <div class="stat-client-sessions__date-wrap">
                    <div class="stat-client-sessions__date">
                      {{ session.to_date}}
                    </div>
                    <div class="stat-client-sessions__time">
                      {{ session.to_time}}
                    </div>
                  </div>
                </div>

                <div class="stat-client-sessions__duration-wrap">
                  <div class="stat-client-sessions__duration-value">
                    {{ session.duration }}
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFieldDate from "../parts/fields/BaseFieldDate.vue";
import Preloader from "../parts/Preloader.vue";
import getLastMonthRange from "../../helpers/dates";
import {mapActions} from "vuex";

export default {
  name: "TheStatClientSessions",
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      tableKey: 'client_sessions_table',
      rows: null,
    };
  },
  components: {
    Preloader,
    BaseFieldDate,
  },
  methods: {
    ...mapActions('stat', {
      'loadStatData':'loadStatData'
    }),
    getFieldId(name) {
      return `${this.tableKey}__${name}`;
    },
    openItem(e) {
      const item = e.target.closest('[data-session-item]');
      item.classList.toggle('_open');
    },
    async updateTable() {
      this.rows = await this.loadStatData({
        statDataKey: this.tableKey,
        params: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        }
      });
    }
  },
  mounted() {
    const range = getLastMonthRange();
    this.dateFrom = range.from;
    this.dateTo = range.to;
    this.updateTable();
  }
}
</script>
