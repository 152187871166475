<template>
  <form class="catalog-filter-short" :class="classModes" @submit.prevent="submit">
    <div class="catalog-filter-short__content">

      <div class="catalog-filter-short__field catalog-filter-short__field_search" v-if="searchField">
        <div class="catalog-filter-short__text-field">
          <label v-if="searchField.label" :for="searchField.key">
            {{ searchField.label }}
          </label>
          <input
              :id="searchField.key"
              :placeholder="searchField.placeholder"
              type="text"
              autocomplete="off"
              class="catalog-filter-short__text-input"
              v-model="searchField.current_value"
              @input="debounceChange"
          >
        </div>
      </div>

      <div class="catalog-filter-short__eav-fields" :class="eavFieldsColumnClass">
        <div class="catalog-filter-short__field" v-for="field in getEavFields">
          <BaseFieldSelectMulti
              v-if="field.view_type === 'select-multi'"
              :values="field.values"
              :field-id="field.key"
              :label="field.label"
              :currentValues="field.current_value"
              :disable-values="field.disable_values"
              :has-filter="field.has_filter"
              :data-type="field.data_type"
              @input="debounceChange"
          />

          <BaseRangeSlider
              v-if="field.view_type === 'range'"
              :field-id="field.key"
              :label="field.label"
              :borders="field.borders"
              :state-value="field.current_value"
              :disabled="field.disabled"
              @change="change"
          />
        </div>
      </div>

      <div  class="catalog-filter-short__field" v-for="field in getGeneralFields" :class="`catalog-filter-short__field_${field.key}`">
        <BaseFieldSelectMulti
            v-if="field.view_type === 'select-multi'"
            :values="field.values"
            :field-id="field.key"
            :label="field.label"
            :currentValues="field.current_value"
            :disable-values="field.disable_values"
            :has-filter="field.has_filter"
            :data-type="field.data_type"
            @input="debounceChange"
        />

        <div v-else-if="field.view_type === 'text'" class="catalog-filter-short__text-field">
          <label v-if="field.label" :for="field.key">
            {{ field.label }}
          </label>
          <input
              :id="field.key"
              :placeholder="field.placeholder"
              type="text"
              autocomplete="off"
              class="catalog-filter-short__text-input"
              v-model="field.current_value"
              @input="debounceChange"
          >
        </div>

        <BaseFieldCheckbox
            v-else-if="field.view_type === 'checkbox'"
            :label="field.label"
            :input-id="field.key"
            :value="field.current_value"
            v-model="field.current_value"
            :disabled="field.disabled"
            @change="change(field)"
        />

        <BaseRangeSlider
            v-if="field.view_type === 'range'"
            :field-id="field.key"
            :label="field.label"
            :borders="field.borders"
            :state-value="field.current_value"
            :disabled="field.disabled"
            @change="change"
        />
      </div>


      <div class="catalog-filter-short__button-wrap catalog-filter-short__button-wrap_submit">
        <button class="catalog-filter-short__button button" :disabled="disableSubmit">
          Подобрать
        </button>
      </div>

      <div class="catalog-filter-short__button-wrap catalog-filter-short__button-wrap_reset">
        <button type="button" class="catalog-filter-short__button button button__secondary" @click="resetFilters">
          Сбросить фильтры
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import BaseFieldSelectMulti from "../parts/fields/BaseFieldSelectMulti.vue";
import BaseFieldCheckboxList from '../parts/fields/BaseFieldCheckboxList.vue';
import BaseFieldCheckbox from '../parts/fields/BaseFieldCheckbox.vue';
import GrayPlusButton from '../parts/GrayPlusButton.vue';
import BaseFieldSwitch from '../parts/fields/BaseFieldSwitch.vue';
import BaseRangeSlider from '../parts/fields/BaseRangeSlider.vue';

export default {
  name: "TheFilterParamShortEav",
  components: {
    BaseFieldSelect,
    BaseFieldCheckboxList,
    BaseFieldCheckbox,
    BaseFieldSelectMulti,
    GrayPlusButton,
    BaseFieldSwitch,
    BaseRangeSlider
  },
  computed: {
    ...mapState('filterParam', {
      'fields': 'fields',
      'enableSecondAxis': 'enableSecondAxis',
    }),
    ...mapState('catalogFilter', {
      'totalProducts': 'totalProducts',
      'settings': 'settings'
    }),
    ...mapGetters('filterParam', {
      'inchField': 'inchField'
    }),
    ...mapState('targetClient', {
      'managerLoaded': 'loaded'
    }),
    searchField() {
      return this.fields.find((field) => field.key === 'search');
    },
    getGeneralFields() {
      const fieldNames = [
          'producer',
          'vendor_code',
          'from_count',
          'avail',
          'price',
          'hide_price',
          'pickup',
      ];
      if (this.managerLoaded) {
        fieldNames.push('city');
        fieldNames.push('internal_id');
      }

      return this.fields
          .filter((field) => fieldNames.includes(field.key))
          .sort((a, b) => {
            if (a.order === b.order) {
              return 0;
            }

            return a.order > b.order ? 1 : -1;
          });
    },

    getEavFields() {
      return this.fields
          .filter((field) => field.key.includes('eav_prop'))
          .filter((field) => field.group === 'main-top')
          .sort((a, b) => a.order > b.order ? 1 : -1);
    },

    eavFieldsColumnClass() {
      const columnClass = 'catalog-filter-short__eav-fields catalog-filter-short__eav-fields';
      const fiveColumns = [5, 9, 10, 13, 14, 15];
      const fields = this.getGeneralFields;
      return fiveColumns.includes(fields.length) ? `${columnClass}_5` : `${columnClass}_3`;
    },

    productType() {
      return this.settings ? this.settings.productType : '';
    },
    classModes() {
      const classes = [];
      if (this.settings) {
        classes.push(`_${this.settings.productType}`)
      }

      classes.push(this.managerLoaded ? '_manager' : '_user');

      return classes.join(' ');
    },

    disableSubmit() {
      return !this.totalProducts;
    }
  },
  methods: {
    ...mapActions('filterParam', {
      'updatePossibleFields': 'updatePossibleFields',
      'debounceUpdatePossibleFields': 'debounceUpdatePossibleFields',
      'fetchProducts': 'fetchProducts',
      'handleCustomField': 'handleCustomField',
      'resetFields': 'resetFields',
      'setEnableSecondAxis': 'setEnableSecondAxis'
    }),
    submit(e) {
      e.preventDefault();
      if (this.totalProducts) {
        this.fetchProducts({ withStat: !this.managerLoaded });
      }
    },
    change(field) {
      if (field.data_type === 'custom') {
        this.handleCustomField(field);
      } else {
        this.updatePossibleFields();
      }
    },
    debounceChange() {
      this.debounceUpdatePossibleFields();
    },
    resetFilters() {
      this.resetFields();
    },
    switchSecondAxis() {
      this.setEnableSecondAxis(!this.enableSecondAxis);
    },
    switchEvent(field) {
      console.log(field)
      if (field.key === 'inch') {
        // this.setCurrentValue({
        //   fieldKey: this.inchField.key,
        //   value
        // });
        this.updatePossibleFields()
      }
    }

  }
}
</script>