require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('../fonts/nunitosans/nunitosans.css');
// require('./common/true-vh');
// require('./common/goal');
require('./common/js-validation');
// require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
// require('./common/map');
// require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/auto-pagination');
require('./common/ajax-redirect');

require('./sliders/sliders');

require('./accordion/initializator');
// require('./accordion/switchable-accordion');

require('./field/phone.js');
// require('./field/range.js'); // Раскоментить при необходимости
require('./field/custom-select');
require('./field/allow-editable');
require('./field/password-input');
require('./field/file-field');
require('./field/images-field');
require('./field/resizable-textarea');
require('../components/smart-tabs/smart-tabs');

require('./forms/identifyFilterForm');
require('./forms/auto-request-form');
require('./forms/markup-client-filter');

// require('./catalog/order-blocks/index');

require('../vue/app.js');

require('./header/index');
require('./menu/mobile-menu');
require('./chat/index');
require('./chat/chat-widget');
require('./print/print');

require('./filter/filter');

require('./recover/recover');

require('./order/order-cancel');
require('./sorter/index');
require('./priceEditor');

require('fslightbox');
require('./FsLightbox/index');

require('./auth/confirmPage');
require('./notifications/read-all-link');