var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog-filter-collection" }, [
    _c("div", { staticClass: "catalog-filter-collection__buttons-wrap" }, [
      _vm.showSaveButton
        ? _c(
            "a",
            {
              staticClass: "catalog-filter-collection__link",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.openModal("save_filter")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "catalog-filter-collection__link-icon" },
                [_c("save-icon")],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "catalog-filter-collection__link-text" },
                [_vm._v("\n        Сохранить поиск\n      ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "catalog-filter-collection__link",
          attrs: { href: "javascript:void(0);" },
          on: { click: _vm.openList },
        },
        [
          _c(
            "span",
            { staticClass: "catalog-filter-collection__link-icon" },
            [_c("filter-icon")],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "catalog-filter-collection__link-text" }, [
            _vm._v("\n        Применить сохраненный фильтр\n      "),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.managerLoaded
        ? _c(
            "div",
            {
              staticClass: "catalog-filter-collection__link",
              attrs: { href: "javascript:void(0);" },
            },
            [
              _c(
                "span",
                { staticClass: "catalog-filter-collection__link-icon" },
                [_c("download-icon")],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "catalog-filter-collection__link-text" },
                [_vm._v("\n        Выгрузить в CSV\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "catalog-filter-collection__link-list" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "catalog-filter-collection__link-item",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.fetchCsv(false)
                        },
                      },
                    },
                    [_vm._v("\n          Старый формат\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "catalog-filter-collection__link-item",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.fetchCsv(true)
                        },
                      },
                    },
                    [_vm._v("\n          Новый формат\n        ")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "catalog-filter-collection__modals-wrap" },
      [
        _c(
          "Popup",
          {
            attrs: {
              opened: _vm.isModalOpened("save_filter"),
              "is-processing": _vm.isProcessing("save_filter"),
            },
            on: {
              close: function ($event) {
                return _vm.closeModal("save_filter")
              },
            },
          },
          [
            _c(
              "form",
              {
                staticClass: "catalog-filter-collection__modal-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "catalog-filter-collection__title" }, [
                  _vm._v("\n          Сохранить параметры поиска\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "catalog-filter-collection__subtitle" },
                  [
                    _vm._v(
                      "\n          Введите название для текущего набора фильтров и сохраните для последующего использования\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterName,
                      expression: "filterName",
                    },
                  ],
                  staticClass: "catalog-filter-collection__input",
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    placeholder: "Название фильтра",
                  },
                  domProps: { value: _vm.filterName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filterName = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.managerLoaded
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.comment,
                          expression: "comment",
                        },
                      ],
                      staticClass: "catalog-filter-collection__comment",
                      attrs: { placeholder: "Комментарий..." },
                      domProps: { value: _vm.comment },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.comment = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "catalog-filter-collection__button button",
                    attrs: { disabled: _vm.isProcessing("save_filter") },
                  },
                  [_vm._v("\n          Сохранить\n        ")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "Popup",
          {
            attrs: {
              opened: _vm.isModalOpened("apply_filter"),
              "is-processing": _vm.isProcessing("apply_filter"),
            },
            on: {
              close: function ($event) {
                return _vm.closeModal("apply_filter")
              },
            },
          },
          [
            _c(
              "form",
              {
                staticClass: "catalog-filter-collection__modal-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.applyFilter.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "catalog-filter-collection__title" }, [
                  _vm._v("\n          Применить сохраненные фильтры\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass:
                      "catalog-filter-collection__tabs tabs tabs_small",
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "tabs__tab",
                        class: { _active: _vm.personalIsActive },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "tabs__tab-link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.setCurrentTab("personal")
                              },
                            },
                          },
                          [_vm._v("\n               Мои фильтры\n            ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tabs__tab",
                        class: { _active: _vm.generalIsActive },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "tabs__tab-link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.setCurrentTab("general")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n               Общие фильтры\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "catalog-filter-collection__selector-wrap" },
                  [
                    _vm.currentList && _vm.currentList.length
                      ? _c(
                          "div",
                          {
                            staticClass: "catalog-filter-collection__selector",
                          },
                          [
                            _c("base-field-select", {
                              attrs: {
                                values: _vm.currentList,
                                "current-value": _vm.selectedFilter,
                                "no-default": true,
                                "field-id": "'filter_save_list'",
                              },
                              model: {
                                value: _vm.selectedFilter,
                                callback: function ($$v) {
                                  _vm.selectedFilter = $$v
                                },
                                expression: "selectedFilter",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "catalog-filter-collection__empty" },
                          [
                            _vm._v(
                              "\n            Нет сохраненных фильтров\n          "
                            ),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "catalog-filter-collection__button button",
                    attrs: {
                      disabled:
                        _vm.isProcessing("apply_filter") || !_vm.selectedFilter,
                    },
                  },
                  [_vm._v("\n          Применить\n        ")]
                ),
                _vm._v(" "),
                _vm.personalIsActive
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "catalog-filter-collection__button button button__secondary",
                        attrs: {
                          type: "button",
                          disabled:
                            _vm.isProcessing("apply_filter") ||
                            !_vm.selectedFilter,
                        },
                        on: { click: _vm.deleteFilter },
                      },
                      [_vm._v("\n          Удалить\n        ")]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }