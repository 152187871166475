import { onDomReady } from '../../components/dynamic/observer';

class MarkupClientFilter {
  constructor(element) {
    this.element = element;
    this.form = element.querySelector('form');
    this.block = document.querySelector('[data-markup-client-list]');
    this.items = this.block.querySelectorAll('[data-item]');
    this.idInput = this.form.querySelector('[data-field-id] input');
    this.searchInput = this.form.querySelector('[data-field-search] input');

    this.bind();

  }

  bind() {
      this.idInput.addEventListener('input', () => {
        this.searchInput.value = '';
        this.filterById();
      });
      this.searchInput.addEventListener('input', () => {
        this.idInput.value = '';
        this.filterBySearch();
      });
  }

  filterById() {
    let value = this.idInput.value;
    value = value.replaceAll(/[^0-9]/gi, '');
    if (value) {
      value = parseInt(value, 10) || 0;
    } else {
      value = '';
    }
    this.idInput.value = value;

    if (value) {
      this.items.forEach((item) => {
        const id = parseInt(item.querySelector('[data-id]').innerHTML.trim());
        if (id === value) {
          item.classList.remove('_hide');
        } else {
          item.classList.add('_hide')
        }
      });
    } else {
      this.showAll();
    }
  }

  filterBySearch() {
    let value = this.searchInput.value;
    value = value.toLowerCase();
    if (value) {
      this.items.forEach((item) => {
        const name = item.querySelector('[data-name]').innerHTML.toLowerCase();
        if (name.includes(value)) {
          item.classList.remove('_hide');
        } else {
          item.classList.add('_hide');
        }
      });
    } else {
      this.showAll();
    }
  }

  showAll() {
    this.items.forEach((item) => item.classList.remove('_hide'));
  }
}
onDomReady(() => {
  const element = document.querySelector('[data-markup-client-filter]');
  if (element) {
    new MarkupClientFilter(element);
  }
})