import axios from 'axios';

const StatClientService = {
  async fetchClientList() {
    return axios.get('/stat/api/client/list');
  },
  async fetchClientActions(clientId, params) {
    return axios.get(`/stat/api/client/actions/${clientId}`, { params });
  },
  async fetchClientSearches(params) {
    return axios.get('/stat/api/client/searches', { params });
  },
  async fetchClientSessions(params) {
    return axios.get('/stat/api/client/sessions', { params });
  },
  async fetchClientViews(params) {
    return axios.get('/stat/api/client/views', { params });
  },
  async fetchClientCarts(params) {
    return axios.get('/stat/api/client/carts', { params });
  },
  async fetchClientOrders(params) {
    return axios.get('/stat/api/client/orders', { params });
  },
  async fetchClientsByDay(params) {
    return axios.get('/stat/api/client/clients_by_day', { params });
  },
  async fetchClientPricesByDay(params) {
    return axios.get('/stat/api/client/client_prices_by_day', { params });
  },
  async fetchClientPricesTotalByDay(params) {
    return axios.get('/stat/api/client/client_prices_total_by_day', { params });
  },
  async fetchClientActivityList(params) {
    return axios.get('/stat/api/client/client_activity_list', { params });
  },
};

export default StatClientService;